
@font2: font2;
.@{font2} {
  &-title {
    font-size: 38px;
  }
  &-title1 {
    font-size: 26px;
  }
  &-img {
    text-align: center;
    height: 80px;
    line-height: 80px;
    & img{
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
    }
  }
  &-text {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .@{font2} {
    &-title {
      font-size: 24px;
    }
    &-text {
      font-size: 14px;
    }
    &-title1 {
      font-size: 16px;
    }
    &-img {
      overflow: hidden;
      text-overflow: ellipsis;white-space: nowrap;
      height: 40px;
      line-height: 40px;
      & img{
        width: 13px;
        height: 13px;
        margin-bottom: 3px;
      }
    }
  }
}
