@content22: content22;
.@{content22}-wrapper {
  height: 360px;
  min-height: 200px;
  margin-bottom: 50px;
  .@{content22} {
    height: 100%;
    padding: 0 24px;
    &-img {
      height: 100%;
      transform-origin: top;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        width: 250px;
        img {
          display: block;
        }
      }
    }
    &-text {
      padding: 0 32px;
      height: 100%;
      .@{content22}-content,
      .@{content22}-title {
        position: relative !important;
      }
      .@{content22}-title {
        //font-size: 32px;
        //font-weight: bold;
        color: #404040;
        margin-top: 120px;
      }
      .@{content22}-content {
        margin-top: 20px;
      }
    }
  }
}
.card22{
  box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
  height: 400px;
  width: 500px;
  //margin-left: 90px;
}
.@{content22}-yingyong{
  min-height:500px;
  padding-top: 20px;
}
.@{content22}-youshi{
  min-height:500px;
}

@media screen and (max-width: 767px) {
  .@{content22}-wrapper {
    height: 600px;
    .@{content22} {
      &-img {
        height: 200px;
        padding: 0;
        text-align: center;
        margin-top: 64px;
        span {
          display: inline-block;
          width: 180px;
          height: 200px;
          line-height: 200px;
          margin: auto;
        }
      }
      &-text {
        height: auto;
        margin-bottom: 20px;
        text-align: center;
        padding: 0;
        .@{content22}-content,
        .@{content22}-title {
          width: 100%;
          top: auto;
        }
        .@{content22}-title {
          margin: 32px auto 16px;
          font-size: 24px;
        }
      }
    }
  }
  .@{content22}-yingyong{
    min-height:200px;
    padding-top: 20px;
  }
  .card22{
    box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
    height: 400px;
    width: 100%;
  }
}
