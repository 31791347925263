
@banner1: banner1;
.@{banner1} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: calc(~"78vh - 64px");
  position: relative;
  border-color: #666;
  background: #fff;
  margin-top: 64px;
  &-wrapper, .banner-anim {
    height: 100%;
  }
  & .queue-anim-leaving {
    position: relative !important;
  }
  .banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
  }
  .banner-user-elem .banner-user-title {
    font-size: 22px;
    top: 40%;
  }
  .banner-user-elem .banner-user-text {
    top: 40%;
  }
  & &-text-wrapper {
    display: block;
    position: relative;
    top: 32%;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 34px;
    color: @template-text-color-light;
    width: 1050px;
    text-align: center;
  }
  & &-title {
    width: 100%;
   // left: 30px;
    margin: auto;
    display: inline-block;
    font-size: 80px;
    position: relative;
  }
  & &-content {
    margin-top: 20px;
    margin-bottom: 20px;
    word-wrap: break-word;
  }
  & &-button {
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
    box-shadow: 0 0 0 transparent;
    transition: background .45s @ease-out, box-shadow .45s @ease-out;
    line-height: 36px;
    font-size: 16px;
    height: 36px;
    & span {
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
      transition: text-shadow .45s @ease-out;
    }
    &:hover {
      color: #fff;
      border-color: #fff;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
      & span {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .35);
      }
    }

    &.queue-anim-leaving {
      width: auto;
    }
  }
  & &-icon {
    bottom: 20px;
    font-size: 24px;
    position: absolute;
    z-index: 10;
    left: 50%;
    margin-left: -12px;
    color: @template-text-color-light;
  }
}

.banner-anim-thumb-default span {
  width: 40px;
  height: 5px;
  border-radius: 1px;
  background: rgba(255, 255, 255, .5);
}

@media screen and (max-width: 767px) {
  .@{banner1} {
    & &-text-wrapper {
      width: 90%;
      .@{banner1}-title {
        width: 90%;
        left: 0;
      }
    }
    .bg {
      background-attachment: inherit;
    }
  }
}
@media screen and (max-width: 767px) {
  .@{banner1} {
    background-attachment: inherit;
    & &-title {
      font-size: 28px;
      width: 100%;
      // left: 30px;
      margin: auto;
      display: inline-block;
      position: relative;
    }
    & &-content {
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: 20px;
      word-wrap: break-word;
    }

  }
}
