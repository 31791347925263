.banner3-title{
  width: 100%;
  // left: 30px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner3-text{
   margin-top: 20px;
   margin-bottom: 20px;
   word-wrap: break-word;
  font-size: 20px;
  max-width: 800px;
}
.banner-anim-elem .bg3 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.custom-arrow-thumb{
  height: 620px;
}
.custom-arrow-thumb .user-arrow {
  top: 50%;
  margin-top: -40px;
}
.custom-arrow-thumb .user-arrow .img-wrapper {
  width: 120px;
  height: 80px;
  float: left;
  position: relative;
}
.custom-arrow-thumb .user-arrow .img-wrapper li {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
}
.custom-arrow-thumb .user-arrow .arrow {
  width: 20px;
  height: 80px;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
}
.custom-arrow-thumb .user-arrow .arrow:before,
.custom-arrow-thumb .user-arrow .arrow:after {
  width: 2px;
  height: 15px;
  background: #fff;
  display: block;
  content: ' ';
  position: absolute;
}
.custom-arrow-thumb .user-arrow.next {
  right: -120px;
}
.custom-arrow-thumb .user-arrow.next .arrow {
  float: left;
}
.custom-arrow-thumb .user-arrow.next .arrow:before {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  top: 28px;
  left: 10px;
}
.custom-arrow-thumb .user-arrow.next .arrow:after {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  bottom: 27px;
  left: 10px;
}
.custom-arrow-thumb .user-arrow.prev {
  left: -120px;
}
.custom-arrow-thumb .user-arrow.prev .arrow {
  float: right;
}
.custom-arrow-thumb .user-arrow.prev .arrow:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  top: 28px;
  left: 8px;
}
.custom-arrow-thumb .user-arrow.prev .arrow:after {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  bottom: 27px;
  left: 8px;
}
.custom-arrow-thumb .user-thumb {
  overflow: hidden;
 // background: rgba(255, 255, 255, 0.15);
  height: 140px;
}
.custom-arrow-thumb .user-thumb > span {
  width: 130px;
  height: 130px;
  margin: 5px;
  box-shadow: 0 0 5px rgba(66, 66, 224, 0.15);
  -webkit-transition: background .3s;
  transition: background .3s;
  background: transparent;
  background: url('../../static/bj-nomal.png');
}
.custom-arrow-thumb .user-thumb > span.active {
  background: url('../../static/bjk-blue.png');
}
.custom-arrow-thumb .user-thumb > span i {
  display: block;
  width: 50px;
  height: 35px;
  margin: 38px;
  background-size: cover;
  background-position: center;
}
