body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
.whiteFont {
  color: #ffffff;
}
.blackFont {
  color: #222222;
}
.blueFont {
  color: #0261A4;
}
.bigTitle {
  font-size: 36px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .bigTitle {
    font-size: 28px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner31 {
  width: 100%;
  height: calc(50vh - 64px);
  position: relative;
  border-color: #666;
  background: #fff;
  margin-top: 64px;
}
.banner31-wrapper,
.banner31 .banner-anim {
  height: 100%;
}
.banner31 .queue-anim-leaving {
  position: relative !important;
}
.banner31 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner31 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner31 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner31 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner31 .banner31-text-wrapper {
  display: block;
  position: relative;
  top: 38%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 34px;
  color: #fff;
  width: 850px;
  text-align: center;
}
.banner31 .banner31-title {
  width: 100%;
  margin: auto;
  display: inline-block;
  font-size: 32px;
  position: relative;
}
.banner31 .banner31-content {
  margin-top: 20px;
  margin-bottom: 20px;
  word-wrap: break-word;
  font-size: 18px;
}
.banner31 .banner31-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner31 .banner31-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner31 .banner31-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner31 .banner31-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner31 .banner31-button.queue-anim-leaving {
  width: auto;
}
.banner31 .banner31-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 40px;
  height: 5px;
  border-radius: 1px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .banner31 .banner31-text-wrapper {
    width: 90%;
  }
  .banner31 .banner31-text-wrapper .banner31-title {
    width: 90%;
    left: 0;
  }
  .banner31 .bg {
    background-attachment: inherit;
  }
}
@media screen and (max-width: 767px) {
  .banner31 {
    background-attachment: inherit;
  }
  .banner31 .banner31-title {
    font-size: 28px;
    width: 100%;
    margin: auto;
    display: inline-block;
    position: relative;
  }
  .banner31 .banner31-content {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    word-wrap: break-word;
  }
}
.content32-wrapper {
  height: 260px;
  min-height: 200px;
}
.content32-wrapper .content32 {
  height: 100%;
  padding: 0 24px;
}
.content32-wrapper .content32-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content32-wrapper .content32-img span {
  display: block;
  width: 250px;
}
.content32-wrapper .content32-img span img {
  display: block;
}
.content32-wrapper .content32-text {
  padding: 0 32px;
  height: 100%;
}
.content32-wrapper .content32-text .content32-content,
.content32-wrapper .content32-text .content32-title {
  position: relative !important;
}
.content32-wrapper .content32-text .content32-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content32-wrapper .content32-text .content32-content {
  margin-top: 20px;
}
.card1 {
  box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
  height: 400px;
  width: 400px;
  margin-left: 90px;
}
.content32-yingyong {
  min-height: 200px;
  padding-top: 20px;
}
.content32-youshi {
  min-height: 500px;
}
@media screen and (max-width: 767px) {
  .content32-wrapper {
    height: 600px;
  }
  .content32-wrapper .content32-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content32-wrapper .content32-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content32-wrapper .content32-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content32-wrapper .content32-text .content32-content,
  .content32-wrapper .content32-text .content32-title {
    width: 100%;
    top: auto;
  }
  .content32-wrapper .content32-text .content32-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
  .content32-yingyong {
    min-height: 200px;
    padding-top: 20px;
  }
  .card1 {
    box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
    height: 400px;
    width: 100%;
  }
}
.feature7-wrapper {
  min-height: 564px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #ffffff;
}
.feature7-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature7-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.feature7-title-sub {
  font-size: 22px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  padding-top: 20px;
}
.feature7-title-h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-title-content {
  margin-top: 16px;
}
.feature7-block {
  margin-top: 28px;
}
.feature7-block-group {
  display: block;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  background-image: url('https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*fMOFSpRXMxsAAAAAAAAAAABkARQnAQ');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature7-block-group:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}
.feature7-block-image {
  float: left;
  width: 24px;
}
.feature7-block-title {
  font-size: 14px;
  float: left;
  margin-left: 8px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-block-content {
  clear: both;
  color: rgba(0, 0, 0, 0.75);
}
@media screen and (max-width: 767px) {
  .feature7-wrapper {
    min-height: 500px;
  }
  .feature7-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px;
  }
}
