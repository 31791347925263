@news0: news0;

.@{news0}-wrapper {
 // min-height: 446px;
  overflow: hidden;
  .home-page {
    padding: 4px 1px!important;
  }
  .@{news0} {
    overflow: hidden;
    height: 100%;
   // padding: 64px 24px;
    min-height: 456px;
    >.title-wrapper {
      margin: 0 auto 48px;
    }

    .block-wrapper {
      position: relative;
      height: 100%;
      overflow: hidden;
      padding: 20px 0;


      .block {
        margin-bottom: 48px;
        vertical-align: text-top;

        &.queue-anim-leaving {
          position: relative !important;
        }
      }
    }

    &-image {
      color: #404040;
      width: 100%;

      img {
        width: 100%;
      }
    }

    &-textWrapper {
      padding-left: 16px;
    }

    &-title {
      font-size: 18px;
      margin-bottom: 2px;
      text-overflow: ellipsis;white-space: nowrap;
      overflow: hidden;
    }

    &-job {
      margin-bottom: 4px;
    }

    &-job,
    &-content {
      font-size: 12px;
      color: #565656;
      height: 60px;
    }
    &-date {
      font-size: 12px;
      color: #919191;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{news0}-wrapper {
    min-height: 420px;
    .@{news0}.home-page {
      padding-bottom: 0;
    }
  }
}
.fade-enter {
  opacity: 0;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-appear {
  opacity: 0;
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-leave {
  animation-duration: .5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-appear.fade-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
