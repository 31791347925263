body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.banner50 {
  width: 100%;
  height: 300px;
  position: relative;
  text-align: center;
  margin-top: 64px;
  border-color: #666;
  background-image: url("../../static/xwd.png");
  background-size: cover;
  background-position: center;
}
.banner50 .banner50-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 40%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 80vw;
  text-align: left;
}
.banner50 .banner50-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner50 .banner50-title {
  width: 100%;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 32px;
  font-weight: bold;
  position: relative;
  text-align: center;
}
.banner50 .banner50-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner50 .banner50-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner50 .banner50-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner50 .banner50-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner50 .banner50-button.queue-anim-leaving {
  width: auto;
}
.banner50 .banner50-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner50 {
    background-attachment: inherit;
  }
  .banner50 .banner50-text-wrapper {
    width: 90%;
  }
  .banner50 .banner50-title {
    width: 90%;
    left: 0;
  }
}
.content51-wrapper {
  min-height: 360px;
}
.content51-wrapper .content51 {
  height: 100%;
  padding: 0 24px;
}
.content51-wrapper .content51-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content51-wrapper .content51-img span {
  display: block;
  width: 250px;
}
.content51-wrapper .content51-img span img {
  display: block;
}
.content51-wrapper .content51-text {
  padding: 0;
  height: 100%;
}
.content51-wrapper .content51-text .content51-content,
.content51-wrapper .content51-text .content51-title {
  position: relative !important;
}
.content51-wrapper .content51-text .content51-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content51-wrapper .content51-text .content51-content {
  margin-top: 20px;
}
.content51-wenhua {
  width: 60vw;
  height: 218px;
}
.content51-wenhua-card1 {
  background: url('../../static/bj-shiming.png') no-repeat;
  background-size: cover;
}
.content51-wenhua-card2 {
  background: url('../../static/bj-qyln.png') no-repeat;
  background-size: cover;
}
.content51-wenhua-card3 {
  background: url('../../static/bj-jiazhiguan.png') no-repeat;
  background-size: cover;
}
.content51-rongyu {
  width: 1458px;
}
.card1 {
  box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
  height: 400px;
  width: 400px;
  margin-left: 90px;
}
.content51-yingyong {
  min-height: 500px;
  padding-top: 20px;
}
.content51-youshi {
  min-height: 500px;
}
@media screen and (max-width: 767px) {
  .content51-wrapper .content51-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content51-wrapper .content51-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content51-wrapper .content51-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content51-wrapper .content51-text .content51-content,
  .content51-wrapper .content51-text .content51-title {
    width: 100%;
    top: auto;
  }
  .content51-wrapper .content51-text .content51-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
  .content51-yingyong {
    min-height: 200px;
    padding-top: 20px;
  }
  .card1 {
    box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
    height: 400px;
    width: 100%;
  }
  .content51-wenhua {
    width: 90vw;
    height: 188px;
  }
}
.content9-wrapper {
  padding-bottom: 64px;
}
.content9-wrapper.home-page-wrapper {
  overflow: initial;
}
.content9-wrapper .content9 {
  min-height: 600px;
  padding: 64px 0 0;
}
.content9-wrapper .timeline {
  position: relative;
}
.content9-wrapper .timeline:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: calc(90% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
}
.content9-wrapper .block-wrapper {
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
}
.content9-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.content9-wrapper .image-wrapper,
.content9-wrapper .text-wrapper {
  width: 50%;
}
.content9-wrapper .image-wrapper {
  text-align: center;
}
.content9-wrapper .image-wrapper .block-img,
.content9-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.content9-wrapper .image-wrapper .block-img {
  height: 98px;
}
.content9-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.content9-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.content9-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 14px;
  margin-bottom: 4px;
}
.content9-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.content9-wrapper .text-wrapper {
  padding-left: 40px;
}
.content9-wrapper .text-wrapper .block-time {
  font-size: 12px;
  color: #697b8c;
  line-height: 18px;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-title {
  margin: 8px 0 12px;
  font-size: 14px;
  position: relative;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  transform: translateX(calc(-50% + 1px));
}
.content9-wrapper .text-wrapper .block-content {
  width: 300px;
  color: #314659;
  font-size: 12px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .content9-wrapper {
    padding-bottom: 0;
  }
  .content9-wrapper .content9 {
    padding: 64px 24px;
  }
  .content9-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .content9-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
  }
  .content9-wrapper .image-wrapper,
  .content9-wrapper .text-wrapper {
    width: 100%;
  }
  .content9-wrapper .image-wrapper .block-img {
    display: none;
  }
  .content9-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .content9-wrapper .image-wrapper .block-name,
  .content9-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .content9-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .content9-wrapper .text-wrapper .block-content {
    display: none;
  }
  .content9-wrapper .text-wrapper .block-title {
    font-size: 16px;
  }
}
