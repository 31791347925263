body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
.whiteFont {
  color: #ffffff;
}
.blackFont {
  color: #222222;
}
.blueFont {
  color: #0261A4;
}
.bigTitle {
  font-size: 36px;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .bigTitle {
    font-size: 28px;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner1 {
  width: 100%;
  height: calc(78vh - 64px);
  position: relative;
  border-color: #666;
  background: #fff;
  margin-top: 64px;
}
.banner1-wrapper,
.banner1 .banner-anim {
  height: 100%;
}
.banner1 .queue-anim-leaving {
  position: relative !important;
}
.banner1 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner1 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner1 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner1 .banner1-text-wrapper {
  display: block;
  position: relative;
  top: 32%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 34px;
  color: #fff;
  width: 1050px;
  text-align: center;
}
.banner1 .banner1-title {
  width: 100%;
  margin: auto;
  display: inline-block;
  font-size: 80px;
  position: relative;
}
.banner1 .banner1-content {
  margin-top: 20px;
  margin-bottom: 20px;
  word-wrap: break-word;
}
.banner1 .banner1-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner1 .banner1-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner1 .banner1-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner1 .banner1-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner1 .banner1-button.queue-anim-leaving {
  width: auto;
}
.banner1 .banner1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 40px;
  height: 5px;
  border-radius: 1px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .banner1 .banner1-text-wrapper {
    width: 90%;
  }
  .banner1 .banner1-text-wrapper .banner1-title {
    width: 90%;
    left: 0;
  }
  .banner1 .bg {
    background-attachment: inherit;
  }
}
@media screen and (max-width: 767px) {
  .banner1 {
    background-attachment: inherit;
  }
  .banner1 .banner1-title {
    font-size: 28px;
    width: 100%;
    margin: auto;
    display: inline-block;
    position: relative;
  }
  .banner1 .banner1-content {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    word-wrap: break-word;
  }
}
.banner2 {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner2 .banner-anim {
  height: 100%;
}
.banner2 .queue-anim-leaving {
  position: relative !important;
  width: auto;
}
.banner2 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner2 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner2 .bg0 {
  background: url('../../static/pingtai2.jpg') center;
  background-size: cover;
  height: 100%;
  width: calc(100% + 100px);
  left: -50px;
}
.banner2 .bg0:before {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
}
.banner2 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner2 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner2-page {
  position: relative;
  top: -8%;
  left: 8%;
  max-width: 2560px;
  margin: auto;
}
.banner2-text-wrapper {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  width: 800px;
  text-align: left;
}
.banner2-text-wrapper .banner2-title {
  left: 0;
  margin: auto;
  position: relative;
  font-size: 56px;
}
.banner2-text-wrapper .banner2-content {
  word-wrap: break-word;
  margin: auto auto 20px;
  font-weight: lighter;
}
.banner2-text-wrapper button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  transition: background 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19), border 0.45s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner2-text-wrapper button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), border 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner2-text-wrapper button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner2-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 414px) {
  .banner2-text-wrapper {
    width: 90%;
    left: 0;
    margin: auto;
    padding: 0;
    text-align: center;
    display: block;
  }
  .banner2-text-wrapper .logo {
    width: 90%;
    left: 0;
  }
  .banner2 .banner2-title {
    left: 0;
    margin: auto;
    position: relative;
    font-size: 28px;
  }
}
.font1-title {
  font-size: 38px;
}
.font1-title1 {
  font-size: 26px;
}
.font1-img {
  text-align: center;
  height: 80px;
  line-height: 80px;
}
.font1-img img {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}
.font1-text {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .font1-title {
    font-size: 24px;
  }
  .font1-text {
    font-size: 14px;
  }
  .font1-title1 {
    font-size: 12px;
  }
  .font1-img {
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .font1-img img {
    width: 13px;
    height: 13px;
    margin-bottom: 3px;
  }
}
.font2-title {
  font-size: 38px;
}
.font2-title1 {
  font-size: 26px;
}
.font2-img {
  text-align: center;
  height: 80px;
  line-height: 80px;
}
.font2-img img {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}
.font2-text {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .font2-title {
    font-size: 24px;
  }
  .font2-text {
    font-size: 14px;
  }
  .font2-title1 {
    font-size: 16px;
  }
  .font2-img {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 40px;
    line-height: 40px;
  }
  .font2-img img {
    width: 13px;
    height: 13px;
    margin-bottom: 3px;
  }
}
.banner3-title {
  width: 100%;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner3-text {
  margin-top: 20px;
  margin-bottom: 20px;
  word-wrap: break-word;
  font-size: 20px;
  max-width: 800px;
}
.banner-anim-elem .bg3 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.custom-arrow-thumb {
  height: 620px;
}
.custom-arrow-thumb .user-arrow {
  top: 50%;
  margin-top: -40px;
}
.custom-arrow-thumb .user-arrow .img-wrapper {
  width: 120px;
  height: 80px;
  float: left;
  position: relative;
}
.custom-arrow-thumb .user-arrow .img-wrapper li {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
}
.custom-arrow-thumb .user-arrow .arrow {
  width: 20px;
  height: 80px;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
}
.custom-arrow-thumb .user-arrow .arrow:before,
.custom-arrow-thumb .user-arrow .arrow:after {
  width: 2px;
  height: 15px;
  background: #fff;
  display: block;
  content: ' ';
  position: absolute;
}
.custom-arrow-thumb .user-arrow.next {
  right: -120px;
}
.custom-arrow-thumb .user-arrow.next .arrow {
  float: left;
}
.custom-arrow-thumb .user-arrow.next .arrow:before {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  top: 28px;
  left: 10px;
}
.custom-arrow-thumb .user-arrow.next .arrow:after {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  bottom: 27px;
  left: 10px;
}
.custom-arrow-thumb .user-arrow.prev {
  left: -120px;
}
.custom-arrow-thumb .user-arrow.prev .arrow {
  float: right;
}
.custom-arrow-thumb .user-arrow.prev .arrow:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  top: 28px;
  left: 8px;
}
.custom-arrow-thumb .user-arrow.prev .arrow:after {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  bottom: 27px;
  left: 8px;
}
.custom-arrow-thumb .user-thumb {
  overflow: hidden;
  height: 140px;
}
.custom-arrow-thumb .user-thumb > span {
  width: 130px;
  height: 130px;
  margin: 5px;
  box-shadow: 0 0 5px rgba(66, 66, 224, 0.15);
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  background: transparent;
  background: url('../../static/bj-nomal.png');
}
.custom-arrow-thumb .user-thumb > span.active {
  background: url('../../static/bjk-blue.png');
}
.custom-arrow-thumb .user-thumb > span i {
  display: block;
  width: 50px;
  height: 35px;
  margin: 38px;
  background-size: cover;
  background-position: center;
}
.feature6-wrapper {
  height: 700px;
  margin: 0 auto;
  overflow: hidden;
  background: url("../../static/bj-qyjj.png") center;
  background-size: cover;
  width: 100%;
}
.feature6-wrapper.home-page-wrapper .home-page {
  padding: 64px 1px;
}
.feature6-title {
  display: inline-block;
}
.feature6-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.feature6-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.feature6-title-text.active {
  color: #1890ff;
}
.feature6-title-bar {
  height: 6px;
  background: #1890ff;
  width: 20%;
  margin: auto;
  display: block;
}
.feature6-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature6-number,
.feature6-text {
  text-align: center;
}
.feature6-number {
  font-size: 45px;
  color: #0261A4;
  font-weight: bold;
}
.feature6-number-wrapper {
  text-align: center;
}
.feature6-text {
  font-size: 20px;
}
.feature6-unit {
  font-size: 16px;
  color: #0261A4;
}
.feature6 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.feature6 .ant-carousel .slick-dots li button {
  background: rgba(24, 144, 255, 0.3);
}
.feature6 .ant-carousel .slick-dots li.slick-active button {
  background: #1890ff;
}
@media screen and (max-width: 767px) {
  .feature6-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .feature6-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature6-title-text {
    margin: 0 14px;
  }
  .feature6-title-bar {
    width: 40%;
  }
  .feature6-number-wrapper {
    margin-bottom: 16px;
  }
}
.feature7-wrapper {
  min-height: 564px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #ffffff;
}
.feature7-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature7-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.feature7-title-h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-title-content {
  margin-top: 16px;
}
.feature7-block {
  margin-top: 28px;
}
.feature7-block-group {
  display: block;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  background-image: url('https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*fMOFSpRXMxsAAAAAAAAAAABkARQnAQ');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature7-block-group:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}
.feature7-block-image {
  float: left;
  width: 24px;
}
.feature7-block-title {
  font-size: 14px;
  float: left;
  margin-left: 8px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.feature7-block-content {
  clear: both;
  color: rgba(0, 0, 0, 0.75);
}
@media screen and (max-width: 767px) {
  .feature7-wrapper {
    min-height: 500px;
  }
  .feature7-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px;
  }
}
.teams2-wrapper {
  min-height: 346px;
  overflow: hidden;
}
.teams2-wrapper .home-page {
  padding: 4px 1px;
}
.teams2-wrapper .teams2 {
  overflow: hidden;
  height: 100%;
}
.teams2-wrapper .teams2 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams2-wrapper .teams2 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
  min-height: 300px;
}
.teams2-wrapper .teams2 .block-wrapper .block {
  margin-bottom: 48px;
  vertical-align: text-top;
}
.teams2-wrapper .teams2 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams2-wrapper .teams2-image {
  color: #404040;
  width: 100%;
}
.teams2-wrapper .teams2-image img {
  width: 100%;
}
.teams2-wrapper .teams2-textWrapper {
  padding-left: 16px;
}
.teams2-wrapper .teams2-title {
  font-size: 18px;
  margin-bottom: 2px;
}
.teams2-wrapper .teams2-job {
  margin-bottom: 4px;
}
.teams2-wrapper .teams2-job,
.teams2-wrapper .teams2-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams2-wrapper {
    min-height: 720px;
  }
  .teams2-wrapper .teams2.home-page {
    padding-bottom: 0;
  }
}
.content2-wrapper {
  height: 400px;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 250px;
}
.content2-wrapper .content2-img span img {
  display: block;
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: bold;
  color: #404040;
  margin-top: 40px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 600px;
  }
  .content2-wrapper .content2-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.news0-wrapper {
  overflow: hidden;
}
.news0-wrapper .home-page {
  padding: 4px 1px!important;
}
.news0-wrapper .news0 {
  overflow: hidden;
  height: 100%;
  min-height: 456px;
}
.news0-wrapper .news0 > .title-wrapper {
  margin: 0 auto 48px;
}
.news0-wrapper .news0 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.news0-wrapper .news0 .block-wrapper .block {
  margin-bottom: 48px;
  vertical-align: text-top;
}
.news0-wrapper .news0 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.news0-wrapper .news0-image {
  color: #404040;
  width: 100%;
}
.news0-wrapper .news0-image img {
  width: 100%;
}
.news0-wrapper .news0-textWrapper {
  padding-left: 16px;
}
.news0-wrapper .news0-title {
  font-size: 18px;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.news0-wrapper .news0-job {
  margin-bottom: 4px;
}
.news0-wrapper .news0-job,
.news0-wrapper .news0-content {
  font-size: 12px;
  color: #565656;
  height: 60px;
}
.news0-wrapper .news0-date {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .news0-wrapper {
    min-height: 420px;
  }
  .news0-wrapper .news0.home-page {
    padding-bottom: 0;
  }
}
.fade-enter {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.fade-appear {
  opacity: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.fade-leave {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.fade-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}
.fade-appear.fade-appear-active {
  animation-name: fadeIn;
  animation-play-state: running;
}
.fade-leave.fade-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
