body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.banner0 {
  width: 100%;
  height: 300px;
  margin-top: 64px;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("../../static/product-1.png");
  background-size: cover;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 80vw;
  text-align: left;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 100%;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
  font-size: 18px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.content22-wrapper {
  height: 360px;
  min-height: 200px;
  margin-bottom: 50px;
}
.content22-wrapper .content22 {
  height: 100%;
  padding: 0 24px;
}
.content22-wrapper .content22-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content22-wrapper .content22-img span {
  display: block;
  width: 250px;
}
.content22-wrapper .content22-img span img {
  display: block;
}
.content22-wrapper .content22-text {
  padding: 0 32px;
  height: 100%;
}
.content22-wrapper .content22-text .content22-content,
.content22-wrapper .content22-text .content22-title {
  position: relative !important;
}
.content22-wrapper .content22-text .content22-title {
  color: #404040;
  margin-top: 120px;
}
.content22-wrapper .content22-text .content22-content {
  margin-top: 20px;
}
.card22 {
  box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
  height: 400px;
  width: 500px;
}
.content22-yingyong {
  min-height: 500px;
  padding-top: 20px;
}
.content22-youshi {
  min-height: 500px;
}
@media screen and (max-width: 767px) {
  .content22-wrapper {
    height: 600px;
  }
  .content22-wrapper .content22-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content22-wrapper .content22-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content22-wrapper .content22-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content22-wrapper .content22-text .content22-content,
  .content22-wrapper .content22-text .content22-title {
    width: 100%;
    top: auto;
  }
  .content22-wrapper .content22-text .content22-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
  .content22-yingyong {
    min-height: 200px;
    padding-top: 20px;
  }
  .card22 {
    box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
    height: 400px;
    width: 100%;
  }
}
