@content2: content2;
.@{content2}-wrapper {
  height: 400px;
  .@{content2} {
    height: 100%;
    padding: 0 24px;
    &-img {
      height: 100%;
      transform-origin: top;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        width: 250px;
        img {
          display: block;
        }
      }
    }
    &-text {
      padding: 0 32px;
      height: 100%;
      .@{content2}-content,
      .@{content2}-title {
        position: relative !important;
      }
      .@{content2}-title {
        font-size: 32px;
        font-weight: bold;
        color: #404040;
        margin-top: 40px;
      }
      .@{content2}-content {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content2}-wrapper {
    height: 600px;
    .@{content2} {
      &-img {
        height: 200px;
        padding: 0;
        text-align: center;
        margin-top: 64px;
        span {
          display: inline-block;
          width: 180px;
          height: 200px;
          line-height: 200px;
          margin: auto;
        }
      }
      &-text {
        height: auto;
        margin-bottom: 20px;
        text-align: center;
        padding: 0;
        .@{content2}-content,
        .@{content2}-title {
          width: 100%;
          top: auto;
        }
        .@{content2}-title {
          margin: 32px auto 16px;
          font-size: 24px;
        }
      }
    }
  }
}
