@content51: content51;
.@{content51}-wrapper {
  //height: 360px;
  min-height: 360px;
  .@{content51} {
    height: 100%;
    padding: 0 24px;
    &-img {
      height: 100%;
      transform-origin: top;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        width: 250px;
        img {
          display: block;
        }
      }
    }
    &-text {
      padding: 0;
      height: 100%;
      .@{content51}-content,
      .@{content51}-title {
        position: relative !important;
      }
      .@{content51}-title {
        font-size: 32px;
        font-weight: normal;
        color: #404040;
        margin-top: 120px;
      }
      .@{content51}-content {
        margin-top: 20px;
      }
    }
  }
}
.@{content51}-wenhua{
  width:60vw;
  height:218px;
  &-card1{
    background:url('../../static/bj-shiming.png') no-repeat;
    background-size: cover;
  }
  &-card2{
    background:url('../../static/bj-qyln.png') no-repeat;
    background-size: cover;
  }
  &-card3{
    background:url('../../static/bj-jiazhiguan.png') no-repeat;
    background-size: cover;
  }
}
.@{content51}-rongyu{
  width:1458px;
}
.card1{
  box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
  height: 400px;
  width: 400px;
  margin-left: 90px;
}
.@{content51}-yingyong{
  min-height:500px;
  padding-top: 20px;
}
.@{content51}-youshi{
  min-height:500px;
}

@media screen and (max-width: 767px) {
  .@{content51}-wrapper {
    //height: 720px;
    .@{content51} {
      &-img {
        height: 200px;
        padding: 0;
        text-align: center;
        margin-top: 64px;
        span {
          display: inline-block;
          width: 180px;
          height: 200px;
          line-height: 200px;
          margin: auto;
        }
      }
      &-text {
        height: auto;
        margin-bottom: 20px;
        text-align: center;
        padding: 0;
        .@{content51}-content,
        .@{content51}-title {
          width: 100%;
          top: auto;
        }
        .@{content51}-title {
          margin: 32px auto 16px;
          font-size: 24px;
        }
      }
    }
  }
  .@{content51}-yingyong{
    min-height:200px;
    padding-top: 20px;
  }
  .card1{
    box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
    height: 400px;
    width: 100%;
  }
  .@{content51}-wenhua{
    width:90vw;
    height:188px;
    &-card1{
      //background:#fff;
      //box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
    }
  }
}
