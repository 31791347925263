@content32: content32;
.@{content32}-wrapper {
  height: 260px;
  min-height: 200px;
  .@{content32} {
    height: 100%;
    padding: 0 24px;
    &-img {
      height: 100%;
      transform-origin: top;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        width: 250px;
        img {
          display: block;
        }
      }
    }
    &-text {
      padding: 0 32px;
      height: 100%;
      .@{content32}-content,
      .@{content32}-title {
        position: relative !important;
      }
      .@{content32}-title {
        font-size: 32px;
        font-weight: normal;
        color: #404040;
        margin-top: 120px;
      }
      .@{content32}-content {
        margin-top: 20px;
      }
    }
  }
}
.card1{
  box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
  height: 400px;
  width: 400px;
  margin-left: 90px;
}
.@{content32}-yingyong{
  min-height:200px;
  padding-top: 20px;
}
.@{content32}-youshi{
  min-height:500px;
}

@media screen and (max-width: 767px) {
  .@{content32}-wrapper {
    height: 600px;
    .@{content32} {
      &-img {
        height: 200px;
        padding: 0;
        text-align: center;
        margin-top: 64px;
        span {
          display: inline-block;
          width: 180px;
          height: 200px;
          line-height: 200px;
          margin: auto;
        }
      }
      &-text {
        height: auto;
        margin-bottom: 20px;
        text-align: center;
        padding: 0;
        .@{content32}-content,
        .@{content32}-title {
          width: 100%;
          top: auto;
        }
        .@{content32}-title {
          margin: 32px auto 16px;
          font-size: 24px;
        }
      }
    }
  }
  .@{content32}-yingyong{
    min-height:200px;
    padding-top: 20px;
  }
  .card1{
    box-shadow: 0 0 10px rgba(227, 227, 227, 0.75);
    height: 400px;
    width: 100%;
  }
}
