body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.banner60 {
  width: 100%;
  height: 300px;
  position: relative;
  text-align: center;
  margin-top: 64px;
  border-color: #666;
  background-image: url("../../static/p-jrwm.png");
  background-size: cover;
  background-position: center;
}
.banner60 .banner60-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 40%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 80vw;
  text-align: left;
}
.banner60 .banner60-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner60 .banner60-title {
  width: 100%;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 32px;
  font-weight: bold;
  position: relative;
  text-align: center;
}
.banner60 .banner60-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner60 .banner60-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner60 .banner60-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner60 .banner60-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner60 .banner60-button.queue-anim-leaving {
  width: auto;
}
.banner60 .banner60-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner60 {
    background-attachment: inherit;
  }
  .banner60 .banner60-text-wrapper {
    width: 90%;
  }
  .banner60 .banner60-title {
    width: 90%;
    left: 0;
  }
}
.content6-wrapper.home-page-wrapper {
  overflow: initial;
}
.content6-wrapper .content6 {
  min-height: 300px;
  padding: 24px 0 0;
}
.content6-wrapper .timeline {
  position: relative;
}
.content6-wrapper .timeline:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: calc(90% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
}
.content6-wrapper .block-wrapper {
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
}
.content6-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.content6-wrapper .image-wrapper,
.content6-wrapper .text-wrapper {
  width: 50%;
}
.content6-wrapper .image-wrapper {
  text-align: center;
}
.content6-wrapper .image-wrapper .block-img,
.content6-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.content6-wrapper .image-wrapper .block-img {
  height: 98px;
}
.content6-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.content6-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.content6-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 14px;
  margin-bottom: 4px;
}
.content6-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.content6-wrapper .text-wrapper {
  padding-left: 40px;
}
.content6-wrapper .text-wrapper .block-time {
  font-size: 12px;
  color: #697b8c;
  line-height: 18px;
  min-height: 18px;
}
.content6-wrapper .text-wrapper .block-title {
  margin: 8px 0 12px;
  font-size: 14px;
  position: relative;
  min-height: 18px;
}
.content6-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  transform: translateX(calc(-50% + 1px));
}
.content6-wrapper .text-wrapper .block-content {
  width: 300px;
  color: #314659;
  font-size: 12px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .content6-wrapper {
    padding-bottom: 0;
  }
  .content6-wrapper .content6 {
    padding: 64px 24px;
  }
  .content6-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .content6-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
  }
  .content6-wrapper .image-wrapper,
  .content6-wrapper .text-wrapper {
    width: 100%;
  }
  .content6-wrapper .image-wrapper .block-img {
    display: none;
  }
  .content6-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .content6-wrapper .image-wrapper .block-name,
  .content6-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .content6-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .content6-wrapper .text-wrapper .block-content {
    display: none;
  }
  .content6-wrapper .text-wrapper .block-title {
    font-size: 16px;
  }
}
